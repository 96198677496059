export const cleanPhoneNumber = value => {
  let cleaned = value;

  if (cleaned) {
    cleaned = cleaned.replace(/[^+\d]/g, '');

    if (!cleaned.startsWith('+')) {
      cleaned = `+${cleaned}`;
    }
  }

  return cleaned;
};

export const normalizeError = error =>
  typeof error === 'string' ? new Error(error) : error;
