import { createAction } from 'redux-actions';
import ActionTypes from './actionTypes';

import { Auth } from '../../lib/amplify';

import { normalizeError } from '../../lib/utils';

const doLogin = createAction(ActionTypes.INITIATE_LOGIN);
const doLogout = createAction(ActionTypes.LOGOUT);
const doSignUp = createAction(ActionTypes.INITIATE_SIGNUP);
const doPasswordReset = createAction(ActionTypes.INITIATE_PASSWORD_RESET);
const doPasswordResetCode = createAction(
  ActionTypes.INITIATE_PASSWORD_RESET_CODE
);
const progress = createAction(ActionTypes.PROGRESS);
const success = createAction(ActionTypes.SUCCESS);
const fail = createAction(ActionTypes.FAILURE);
const cancel = createAction(ActionTypes.CANCEL);
const confirmation = createAction(ActionTypes.AWAIT_CONFIRMATION);

export default {
  logout: () => dispatch => {
    dispatch(progress());

    Auth.signOut()
      .then(() => dispatch(doLogout()))
      .catch(console.error);
  },
  login: () => dispatch => {
    dispatch(progress());

    Auth.currentAuthenticatedUser()
      .then(user => {
        if (user && user.signInUserSession) {
          const {
            email,
            name,
            // eslint-disable-next-line camelcase
            phone_number
          } = user.signInUserSession.idToken.payload;

          dispatch(success({ email, name, phone: phone_number }));
        } else {
          dispatch(doLogin());
        }
      })
      .catch(() => {
        dispatch(doLogin());
      });
  },
  signIn: state => dispatch => {
    dispatch(progress());

    Auth.signIn(state.email, state.password)
      .then(result => {
        if (result.signInUserSession) {
          const {
            email,
            name,
            // eslint-disable-next-line camelcase
            phone_number
          } = result.signInUserSession.idToken.payload;
          dispatch(success({ email, name, phone: phone_number }));
        } else {
          console.warn('NOT SUPPORTED: password reset required');
          // ToDo: the user might be forced to reset their password (PASSWORD_VERIFIER)
          // ToDo: the account can be locked after unsuccesfull signin attempts
        }
      })
      .catch(error => dispatch(fail(normalizeError(error))));
  },
  signUp: state => dispatch => {
    dispatch(progress());

    Auth.signUp({
      username: state.email,
      password: state.password,
      attributes: Object.assign(
        {
          name: state.name
        },
        state.phone && { phone_number: state.phone }
      )
    })
      .then(result => dispatch(confirmation()))
      .catch(error => dispatch(fail(normalizeError(error))));
  },
  passwordReset: state => dispatch => {
    dispatch(progress());

    Auth.forgotPassword(state.email)
      .then(result => dispatch(doPasswordResetCode()))
      .catch(error => dispatch(fail(normalizeError(error))));
  },
  passwordResetConfirm: state => dispatch => {
    dispatch(progress());

    Auth.forgotPasswordSubmit(state.email, state.code, state.password)
      .then(result => dispatch(confirmation()))
      .catch(error => dispatch(fail(normalizeError(error))));
  },
  doLogin,
  doSignUp,
  doPasswordReset,
  cancel
};
