import React from 'react';

const Confirmation = () => (
  <React.Fragment>
    <svg
      version="1.1"
      id="Layer_1"
      //xmlns="http://www.w3.org/2000/svg"
      //xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width="100px"
      height="100px"
      viewBox="0 0 100 100"
      enableBackground="new 0 0 100 100"
      xmlSpace="preserve"
    >
      <polyline
        className="success"
        fill="none"
        stroke="#008F84"
        strokeWidth="10"
        strokeMiterlimit="20"
        points="15,60 40,80 85,20"
      />
    </svg>
    <h1>You are all set</h1>
    <p className="success__text">
      Go ahead and sign in to start tracking your travel!
    </p>
  </React.Fragment>
);

export default Confirmation;
