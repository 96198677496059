import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  addMonths,
  startOfDay,
  startOfMonth,
  lastDayOfMonth,
  isEqual,
  format,
  addDays,
  isWeekend,
  getYear
} from 'date-fns';
import classNames from 'classnames';

import { holidaysIn } from '../../lib/holidays';
import { isCalendarEqual } from '../../lib/calendar';

// eslint-disable-next-line func-names
const daysInMonth = function*(date) {
  const start = startOfMonth(date);
  const end = lastDayOfMonth(date);

  for (let day = start; day <= end; day = addDays(day, 1)) {
    yield day;
  }
};

class TravelCalendar extends Component {
  constructor(props) {
    super(props);

    this.state = { context: startOfMonth(startOfDay(new Date())) };

    this.shift = this.shift.bind(this);
    this.today = this.today.bind(this);
    this.update = this.update.bind(this);
  }

  today() {
    this.setState({ context: startOfMonth(startOfDay(new Date())) });
  }

  shift(direction) {
    return () =>
      this.setState({ context: addMonths(this.state.context, direction) });
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      nextProps.update !== this.props.update || // changed from private to public URL
      !isEqual(nextState.context, this.state.context) || // changed what month you are looking at
      !isCalendarEqual(nextProps.calendar, this.props.calendar) // deep comparison of the calendar contents
    );
  }

  update(day) {
    // ToDo: it would be nice to add RxJS and accumulate updates before we send them off
    return () => {
      if (this.props.calendar.find(el => isEqual(el, day))) {
        this.props.update([], [day]);
      } else {
        this.props.update([day], []);
      }
    };
  }

  render() {
    const { context } = this.state;
    const today = startOfDay(new Date());
    const holidays = holidaysIn(getYear(context));

    const thisMonthLabel = format(context, 'MMM');
    const prevMonthLabel = format(addMonths(context, -1), 'MMM');
    const nextMonthLabel = format(addMonths(context, 1), 'MMM');

    const days = [];
    for (const day of daysInMonth(context)) {
      days.push(
        <li
          onClick={this.props.update ? this.update(day) : undefined}
          className={classNames('date', {
            current: isEqual(day, today),
            weekend: isWeekend(day) || holidays.isHoliday(day)
          })}
          key={day}
        >
          <span className="day">{format(day, 'ddd').toUpperCase()}</span>
          <span className="digit">{format(day, 'DD')}</span>
          <span className="month-short">{thisMonthLabel}</span>
          <span
            className={classNames('indicator', {
              selected: this.props.calendar.find(el => isEqual(el, day))
            })}
          />
        </li>
      );
    }

    return (
      <div className="container">
        <div className="datepicker">
          <button className="datepicker__today-toggle" onClick={this.today}>
            TODAY
          </button>
          <button
            className="datepicker__month-toggle datepicker__month-toggle--prev"
            onClick={this.shift(-1)}
          >
            {prevMonthLabel}
          </button>
          <ul className="month">{days}</ul>
          <button
            className="datepicker__month-toggle datepicker__month-toggle--next"
            onClick={this.shift(+1)}
          >
            {nextMonthLabel}
          </button>
        </div>
      </div>
    );
  }
}

TravelCalendar.propTypes = {
  calendar: PropTypes.arrayOf(PropTypes.instanceOf(Date)),
  update: PropTypes.func
};

export default TravelCalendar;
