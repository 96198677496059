import { isEqual } from 'date-fns';

export const isCalendarEqual = (calendarOne = [], calendarTwo = []) => {
  if (calendarOne.length !== calendarTwo.length) {
    return false;
  }

  const oneInTwo = calendarOne.every(dateOne =>
    calendarTwo.find(dateTwo => isEqual(dateOne, dateTwo))
  );
  const twoInOne = calendarTwo.every(dateTwo =>
    calendarOne.find(dateOne => isEqual(dateOne, dateTwo))
  );

  return oneInTwo && twoInOne;
};
