import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import actions from './actions';

import Signin from './Signin';
import Signup from './Signup';
import Confirmation from './Confirmation';
import PasswordReset from './PasswordReset';

// ToDo: handle PASSWORD_VERIFIER challenge (FORCE_CHANGE_PASSWORD)
// ToDo: handle voluntery password reset
// ToDo: the component became too big and needs to be splited into individual Login, SignUp, etc.

class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {};

    this.handleChange = this.handleChange.bind(this);
    this.login = this.login.bind(this);
    this.signUp = this.signUp.bind(this);
    this.cancel = this.cancel.bind(this);
    this.doSignUp = this.doSignUp.bind(this);
    this.doLogin = this.doLogin.bind(this);
    this.passwordReset = this.passwordReset.bind(this);
    this.passwordResetConfirm = this.passwordResetConfirm.bind(this);
    this.doPasswordReset = this.doPasswordReset.bind(this);
  }

  handleChange(attr = 'value', processor = value => value) {
    return e => this.setState({ [e.target.id]: processor(e.target[attr]) });
  }

  login() {
    this.props.signIn(this.state);
    this.clear();
  }

  signUp() {
    this.props.signUp(this.state);
    this.clear();
  }

  passwordReset() {
    this.props.passwordReset(this.state);
  }

  passwordResetConfirm() {
    this.props.passwordResetConfirm(this.state);
    this.clear();
  }

  clear() {
    this.setState({
      code: undefined,
      password: undefined,
      password2: undefined
    });
  }

  doSignUp() {
    this.clear();
    this.props.doSignUp();
  }

  doLogin() {
    this.clear();
    this.props.doLogin();
  }

  doPasswordReset() {
    this.clear();
    this.props.doPasswordReset();
  }

  cancel() {
    this.clear();
    this.props.cancel();
  }

  render() {
    const isLoading = Boolean(this.props.progress);

    return (
      <React.Fragment>
        <div
          className={classNames(['modal', this.props.mode], {
            visible: Boolean(this.props.mode),
            success: this.props.mode === 'confirmation'
          })}
        >
          <div className={classNames('progress', { visible: isLoading })}>
            <div className="inner"> </div>
          </div>

          {this.props.mode === 'signin' && (
            <Signin
              isLoading={isLoading}
              email={this.state.email}
              password={this.state.password}
              handleChange={this.handleChange}
              login={this.login}
              doSignUp={this.doSignUp}
              doPasswordReset={this.doPasswordReset}
              error={this.props.error}
            />
          )}

          {this.props.mode === 'password_reset_1' && (
            <PasswordReset
              step="1"
              isLoading={isLoading}
              email={this.state.email}
              handleChange={this.handleChange}
              passwordReset={this.passwordReset}
              passwordResetConfirm={this.passwordResetConfirm}
              doLogin={this.doLogin}
              error={this.props.error}
            />
          )}

          {this.props.mode === 'password_reset_2' && (
            <PasswordReset
              step="2"
              isLoading={isLoading}
              email={this.state.email}
              code={this.state.code}
              password={this.state.password}
              password2={this.state.password2}
              handleChange={this.handleChange}
              passwordReset={this.passwordReset}
              passwordResetConfirm={this.passwordResetConfirm}
              doLogin={this.doLogin}
              error={this.props.error}
            />
          )}

          {this.props.mode === 'signup' && (
            <Signup
              isLoading={isLoading}
              email={this.state.email}
              name={this.state.name}
              phone={this.state.phone}
              password={this.state.password}
              password2={this.state.password2}
              consent={this.state.consent}
              handleChange={this.handleChange}
              signUp={this.signUp}
              doLogin={this.doLogin}
              error={this.props.error}
            />
          )}

          {this.props.mode === 'confirmation' && <Confirmation />}
        </div>

        <div
          className={classNames('overlay', {
            visible: Boolean(this.props.mode)
          })}
          onClick={this.cancel}
        >
          <button
            onClick={this.cancel}
            type="button"
            className="button-close"
          />
        </div>
      </React.Fragment>
    );
  }
}

Login.propTypes = {
  mode: PropTypes.string,
  progress: PropTypes.bool,
  error: PropTypes.shape({ message: PropTypes.string }),
  signIn: PropTypes.func.isRequired,
  signUp: PropTypes.func.isRequired,
  passwordReset: PropTypes.func.isRequired,
  passwordResetConfirm: PropTypes.func.isRequired,
  doLogin: PropTypes.func.isRequired,
  doSignUp: PropTypes.func.isRequired,
  doPasswordReset: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired
};

export default connect(
  state => ({ ...state.auth }),
  { ...actions }
)(Login);
