import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { userType } from '../../types';
import Account from './Account';
import Share from './Share';

class JournalHeader extends PureComponent {
  render() {
    const accountVisible = this.props.profile && this.props.profile.initiated;
    const sharingVisible = this.props.share && this.props.share.initiated;

    return (
      <React.Fragment>
        <div className="container">
          <div className="top-nav">
            <h1>
              <a href="/">Trevelevel</a>
              {this.props.public && (
                <div className="owner">
                  of{' '}
                  <a href={`mailto:${this.props.email}`}>{this.props.email}</a>
                </div>
              )}
            </h1>
            <div className="user-nav">
              {!this.props.public && (
                <button
                  className="button button--small button--blue"
                  type="button"
                  onClick={this.props.initiateProfileSharing}
                >
                  Share
                </button>
              )}
              {!this.props.public &&
                this.props.user && (
                  <button
                    className="account"
                    type="button"
                    onClick={this.props.initiateProfileUpdate}
                  />
                )}
              {this.props.user && (
                <button
                  className="link-button"
                  type="button"
                  onClick={this.props.logout}
                >
                  Logout
                </button>
              )}
            </div>
          </div>
        </div>

        {accountVisible && (
          <Account
            user={this.props.user}
            progress={this.props.profile.progress}
            error={this.props.profile.error}
            // whether delete action has been initiated
            delete={this.props.profile.delete}
            // actions
            updateProfile={this.props.updateProfile}
            initiateProfileDelete={this.props.initiateProfileDelete}
            cancelProfileDelete={this.props.cancelProfileDelete}
            deleteProfile={this.props.deleteProfile}
          />
        )}

        {sharingVisible && (
          <Share
            user={this.props.user}
            shared={this.props.shared}
            progress={this.props.share.progress}
            error={this.props.share.error}
            // actions
            shareProfile={this.props.shareProfile}
          />
        )}

        <div
          className={classNames('overlay', {
            visible: accountVisible || sharingVisible
          })}
          onClick={this.props.close}
        >
          <button
            onClick={this.props.close}
            type="button"
            className="button-close"
          />
        </div>
      </React.Fragment>
    );
  }
}

JournalHeader.propTypes = {
  public: PropTypes.bool.isRequired,
  shared: PropTypes.bool.isRequired,
  email: PropTypes.string.isRequired,
  user: userType,

  // state of popups
  profile: PropTypes.object,
  share: PropTypes.object,

  // actions
  logout: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
  initiateProfileUpdate: PropTypes.func.isRequired,
  initiateProfileDelete: PropTypes.func.isRequired,
  cancelProfileDelete: PropTypes.func.isRequired,
  deleteProfile: PropTypes.func.isRequired,
  updateProfile: PropTypes.func.isRequired,
  initiateProfileSharing: PropTypes.func.isRequired,
  shareProfile: PropTypes.func.isRequired
};

export default JournalHeader;
