import {
  addWeeks,
  addDays,
  setDay,
  lastDayOfMonth,
  getMonth,
  parse,
  format
} from 'date-fns';

const lastDayForMonth = ({ year, day, month }) => {
  const ending = lastDayOfMonth(parse(`${year}-${month + 1}-01`));
  const result = setDay(ending, day);
  if (getMonth(result) === month) {
    return result;
  }

  return addWeeks(result, -1);
};

const firstDayForMonth = ({ year, day, month }) => {
  const beginning = parse(`${year}-${month + 1}-01`);
  const result = setDay(beginning, day);
  if (getMonth(result) === month) {
    return result;
  }

  return addWeeks(result, 1);
};

const nthDaysForMonth = (n, day) => {
  const firstDay = firstDayForMonth(day);

  return addWeeks(firstDay, n - 1);
};

export const holidaysIn = year => {
  const memorial = lastDayForMonth({ year, day: 1, month: 4 });
  const labor = firstDayForMonth({ year, day: 1, month: 8 });
  const thanksgiving = nthDaysForMonth(4, { year, day: 4, month: 10 });
  const dayAfterThanksgiving = addDays(thanksgiving, 1);

  const holidays = {
    [`${year}-01-01`]: 'New Year\'s Day',
    [format(memorial, 'YYYY-MM-DD')]: 'Memorial Day',
    [`${year}-07-04`]: 'Independence Day',
    [format(labor, 'YYYY-MM-DD')]: 'Labor Day',
    [format(thanksgiving, 'YYYY-MM-DD')]: 'Thanksgiving Day',
    [format(dayAfterThanksgiving, 'YYYY-MM-DD')]: 'Day after Thanksgiving Day',
    [`${year}-12-24`]: 'Christmas Eve',
    [`${year}-12-25`]: 'Christmas Day',
    [`${year}-12-31`]: 'New Year\'s Eve'
  };

  return {
    isHoliday: date => holidays[format(date, 'YYYY-MM-DD')],
    list: holidays
  };
};
