import ActionTypes from './actionTypes';

export default (state, action) => {
  switch (action.type) {
    case ActionTypes.CLOSE_JOURNAL_POPUPS:
      return {
        ...state,
        profile: {},
        share: {}
      };
    case ActionTypes.JOURNAL_LOADING:
      return {
        ...state,
        error: undefined,
        progress: true
      };
    case ActionTypes.JOURNAL_ERROR:
      return {
        ...state,
        progress: false,
        error: action.payload
      };
    case ActionTypes.JOURNAL_LOADED:
      return {
        ...state,
        progress: false,
        error: undefined,
        ...action.payload // { calendar, shared }
      };
    case ActionTypes.PROFILE_UPDATE_INITIATED:
      return {
        ...state,
        profile: {
          initiated: true
        },
        share: {
          initiated: false
        }
      };
    case ActionTypes.PROFILE_UPDATE_PROCESSING:
      return {
        ...state,
        profile: {
          initiated: true,
          progress: true
        }
      };
    case ActionTypes.PROFILE_UPDATE_COMPLETE:
      // ToDo: how do we update the state with the new user's email ????
      return {
        ...state,
        profile: {
          initiated: false
        }
      };
    case ActionTypes.PROFILE_UPDATE_ERROR:
      return {
        ...state,
        profile: {
          initiated: true, // stays open
          progress: false,
          error: action.payload
        }
      };
    case ActionTypes.PROFILE_DELETE_INITIATED:
      return {
        ...state,
        profile: {
          initiated: true,
          delete: true
        }
      };
    case ActionTypes.PROFILE_DELETE_PROCESSING:
      return {
        ...state,
        profile: {
          initiated: true,
          progress: true,
          delete: true
        }
      };
    case ActionTypes.PROFILE_DELETE_COMPLETE:
      return {
        ...state,
        profile: {},
        share: {}
      };
    case ActionTypes.PROFILE_DELETE_ERROR:
      return {
        ...state,
        profile: {
          initiated: true,
          delete: true,
          progress: false,
          error: action.payload
        }
      };
    case ActionTypes.PROFILE_DELETE_CANCEL:
      return {
        ...state,
        profile: {
          initiated: true
        }
      };
    case ActionTypes.PROFILE_SHARE_INITIATED:
      return {
        ...state,
        profile: {
          initiated: false
        },
        share: {
          initiated: true
        }
      };
    case ActionTypes.PROFILE_SHARE_PROCESSING:
      return {
        ...state,
        share: {
          initiated: true,
          progress: true
        }
      };
    case ActionTypes.PROFILE_SHARE_UPDATED:
      return {
        ...state,
        share: {
          initiated: true,
          progress: false
        }
      };
    case ActionTypes.PROFILE_SHARE_ERROR:
      return {
        ...state,
        share: {
          initiated: true,
          progress: false,
          error: action.payload
        }
      };
    default:
      return (
        state || {
          calendar: [],
          shared: false,
          profile: {},
          share: {}
        }
      );
  }
};
