import React from 'react';
import { Provider } from 'react-redux';
import { HashRouter, Switch, Route, Redirect } from 'react-router-dom';
import Welcome from './pages/Welcome';
import Journal from './pages/Journal';
import Login from './components/Login';
import store from './store';

import './css/normalize.css';
import './css/index.css';

// ToDo: logout from within a Journal action sends me to a weird URL for some reason: http://localhost:3500/?#/

const App = () => (
  <Provider store={store}>
    <div className="app">
      <Login />
      <HashRouter>
        <Switch>
          <Route exact path="/" component={Welcome} />
          <Route
            exact
            path="/:email"
            render={({ match }) => (
              <Journal public={false} email={match.params.email} />
            )}
          />
          <Route
            exact
            path="/public/:email"
            render={({ match }) => (
              <Journal public={true} email={match.params.email} />
            )}
          />
          <Route>
            <Redirect to="/" />
          </Route>
        </Switch>
      </HashRouter>
    </div>
  </Provider>
);

export default App;
