import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { cleanPhoneNumber } from '../../../lib/utils';

class Signup extends PureComponent {
  render() {
    const isValid =
      this.props.email &&
      this.props.name &&
      this.props.password &&
      this.props.consent &&
      this.props.password === this.props.password2;

    return (
      <React.Fragment>
        <h1>Sign up</h1>
        <form className="form">
          <div className="form-field">
            <label htmlFor="email">Email</label>
            <input
              id="email"
              type="email"
              required={true}
              onChange={this.props.handleChange()}
              disabled={this.props.isLoading}
              value={this.props.email || ''}
            />
          </div>

          <div className="form-field">
            <label htmlFor="name">Name</label>
            <input
              id="name"
              type="text"
              required={true}
              onChange={this.props.handleChange()}
              disabled={this.props.isLoading}
              value={this.props.name || ''}
            />
          </div>
          <div className="form-field">
            <label htmlFor="phone">Phone</label>
            <input
              id="phone"
              type="text"
              onChange={this.props.handleChange('value', cleanPhoneNumber)}
              disabled={this.props.isLoading}
              value={this.props.phone || ''}
            />
            <span className="form-field__message">
              Only if you want to be able to text Trevelevel. A phone number
              must start with a plus (+) sign, followed immediately by the
              country code. We will strip out everything else as you type.
            </span>
          </div>

          <div className="form-field">
            <label htmlFor="password">Password</label>
            <input
              id="password"
              type="password"
              required={true}
              onChange={this.props.handleChange()}
              disabled={this.props.isLoading}
              value={this.props.password || ''}
            />
          </div>
          <div className="form-field">
            <label htmlFor="confirm-password">Confirm password</label>
            <input
              id="password2"
              type="password"
              required={true}
              onChange={this.props.handleChange()}
              disabled={this.props.isLoading}
              value={this.props.password2 || ''}
            />
          </div>
          <div className="form-field">
            <label className="checkbox">
              <span className="checkbox__text">Accept:</span>
              <p className="checkbox__text__smallprint">
                This site does not use cookies but we do use local storage. We
                only keep track of the dates you say you traveled and we do not
                remember anything else you say via Alexa or text messages. You
                can delete your account at any time. Nothing we collect about
                you is shared with any third party.
              </p>
              <input
                id="consent"
                className="checkbox__input"
                type="checkbox"
                onChange={this.props.handleChange('checked')}
                required={true}
              />
              <span className="checkbox__checkmark" />
            </label>
          </div>

          {this.props.error && (
            <div className="message-box message-box--error">
              <h4>Oops...</h4>
              <p className="message-box__message">{this.props.error.message}</p>
            </div>
          )}
          <div className="button-container">
            <button
              type="submit"
              className="button"
              onClick={this.props.signUp}
              disabled={!isValid || this.props.isLoading}
            >
              Sign Up
            </button>
            <a onClick={this.props.doLogin} disabled={this.props.isLoading}>
              Sign in
            </a>
          </div>
        </form>
      </React.Fragment>
    );
  }
}

Signup.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  email: PropTypes.string,
  name: PropTypes.string,
  phone: PropTypes.string,
  password: PropTypes.string,
  password2: PropTypes.string,
  consent: PropTypes.bool,

  handleChange: PropTypes.func.isRequired,
  signUp: PropTypes.func.isRequired,
  doLogin: PropTypes.func.isRequired,

  error: PropTypes.shape({ message: PropTypes.string })
};

export default Signup;
