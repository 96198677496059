import { createAction } from 'redux-actions';

import { Auth } from '../../../lib/amplify';
import api from '../../../lib/api';
import { normalizeError } from '../../../lib/utils';

import ActionTypes from '../actionTypes';
import login from '../../../components/Login/actionTypes';

const startProfileUpdate = createAction(ActionTypes.PROFILE_UPDATE_INITIATED);
const profileUpdateProcessing = createAction(
  ActionTypes.PROFILE_UPDATE_PROCESSING
);
const profileUpdated = createAction(ActionTypes.PROFILE_UPDATE_COMPLETE);
const profileUpdateError = createAction(ActionTypes.PROFILE_UPDATE_ERROR);

const startProfileDelete = createAction(ActionTypes.PROFILE_DELETE_INITIATED);
const cancelProfileDelete = createAction(ActionTypes.PROFILE_DELETE_CANCEL);
const profileDeteleProcessing = createAction(
  ActionTypes.PROFILE_DELETE_PROCESSING
);
const profileDeleted = createAction(ActionTypes.PROFILE_DELETE_COMPLETE);
const profileDeleteError = createAction(ActionTypes.PROFILE_DELETE_ERROR);

const doLogout = createAction(login.LOGOUT);
const updateUser = createAction(login.SUCCESS);

export default {
  initiateProfileUpdate: () => dispatch => {
    dispatch(startProfileUpdate());
  },

  initiateProfileDelete: () => dispatch => {
    dispatch(startProfileDelete());
  },

  cancelProfileDelete: () => dispatch => {
    dispatch(cancelProfileDelete());
  },

  deleteProfile: () => dispatch => {
    dispatch(profileDeteleProcessing());

    api
      .deleteProfile()
      .then(() => dispatch(profileDeleted()))
      .then(() => Auth.signOut())
      .then(() => dispatch(doLogout()))
      .catch(error => dispatch(profileDeleteError(normalizeError(error))));
  },

  updateProfile: (email, name, phone) => dispatch => {
    dispatch(profileUpdateProcessing());

    api
      .updateProfile(email, name, phone)
      .then(() => dispatch(profileUpdated()))
      .then(() => dispatch(updateUser({ email, name, phone })))
      .catch(error => dispatch(profileUpdateError(normalizeError(error))));
  }
};
