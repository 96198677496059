import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { parse } from 'date-fns';
import { Chart } from 'chart.js';

import { calculateMonthly } from '../../lib/indicator';

const lines = [
  {
    label: '',
    attr: 'pessimistic',
    color: '#FFAD27'
  },
  {
    label: '',
    attr: 'normal',
    color: '#2059B8'
  },
  {
    label: '',
    attr: 'family',
    color: '#008F84'
  }
];

const months = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec'
];

class TravelChart extends PureComponent {
  constructor(props) {
    super(props);

    this.canvas = React.createRef();
  }

  componentDidMount() {
    this.draw();
  }

  componentDidUpdate() {
    this.draw();
  }

  draw() {
    const dates = calculateMonthly(
      this.props.calendar.map(parse),
      this.props.mode
    );

    const data = {
      labels: Object.keys(dates).map(
        month => months[Number(month.replace('M-', ''))]
      ),
      datasets: lines.map(line => ({
        label: line.label,
        backgroundColor: line.color,
        borderColor: line.color,
        data: Object.keys(dates).map(month => dates[month][line.attr]),
        fill: false,
        radius: 5
      }))
    };

    if (this.chart) {
      this.chart.data = data;
      this.chart.update({
        duration: 0
      });
    } else {
      this.chart = new Chart(this.canvas.current, {
        type: 'line',
        data,
        options: {
          responsive: true,
          scales: {
            yAxes: [
              {
                ticks: {
                  callback: value => value + '%'
                }
              }
            ],
            xAxes: [
              {
                gridLines: {
                  display: false
                }
              }
            ]
          },
          title: {
            display: false
          },
          legend: {
            display: false
          },
          tooltips: {
            enabled: false
          }
        }
      });
    }
  }

  render() {
    return (
      <div className="container">
        <div className="block">
          <canvas id="monthly" width="400" height="150" ref={this.canvas} />
        </div>
      </div>
    );
  }
}

TravelChart.propTypes = {
  calendar: PropTypes.arrayOf(PropTypes.instanceOf(Date)),
  mode: PropTypes.number.isRequired
};

export default TravelChart;
