export default {
  JOURNAL_LOADING: 'JOURNAL_LOADING',
  JOURNAL_LOADED: 'JOURNAL_LOADED',
  JOURNAL_ERROR: 'JOURNAL_ERROR',

  JOURNAL_NOT_FOUND: 'JOURNAL_NOT_FOUND',
  JOURNAL_NOT_PUBLIC: 'JOURNAL_NOT_PUBLIC',

  CLOSE_JOURNAL_POPUPS: 'CLOSE_JOURNAL_POPUPS',

  PROFILE_UPDATE_INITIATED: 'PROFIE_UPDATE_INITIATED',
  PROFILE_UPDATE_PROCESSING: 'PROFILE_UPDATE_PROCESSING',
  PROFILE_UPDATE_COMPLETE: 'PROFILE_UPDATE_COMPLETE',
  PROFILE_UPDATE_ERROR: 'PROFILE_UPDATE_ERROR',

  PROFILE_SHARE_INITIATED: 'PROFILE_SHARE_INITIATED',
  PROFILE_SHARE_PROCESSING: 'PROFILE_SHARE_PROCESSING',
  PROFILE_SHARE_UPDATED: 'PROFILE_SHARE_UPDATED',
  PROFILE_SHARE_ERROR: 'PROFILE_SHARE_ERROR',

  PROFILE_DELETE_INITIATED: 'PROFILE_DELETE_INITIATED',
  PROFILE_DELETE_PROCESSING: 'PROFILE_DELETE_PROCESSING',
  PROFILE_DELETE_COMPLETE: 'PROFILE_DELETE_COMPLETE',
  PROFILE_DELETE_ERROR: 'PROFILE_DELETE_ERROR',
  PROFILE_DELETE_CANCEL: 'PROFILE_DELETE_CANCEL'
};
