import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class PasswordReset extends PureComponent {
  render() {
    const isValid =
      this.props.email &&
      (this.props.step === '1' ||
        (this.props.code &&
          this.props.password &&
          this.props.password === this.props.password2));

    return (
      <React.Fragment>
        <h1>Reset Password</h1>
        <form className="form">
          <div className="form-field">
            <label htmlFor="email">Email</label>
            <input
              id="email"
              type="email"
              required={true}
              autoFocus={true}
              disabled={this.props.isLoading || this.props.step === '2'}
              tabIndex={0}
              onChange={this.props.handleChange()}
              value={this.props.email || ''}
            />
            {this.props.step === '1' && (
              <span className="form-field__message">
                If we find an account with this email address, we will send you
                a verification code that you will use on the next step to set a
                new password.
              </span>
            )}
          </div>
          {this.props.step === '2' && (
            <React.Fragment>
              <div className="form-field">
                <label htmlFor="code">Confirmation Code</label>
                <input
                  id="code"
                  type="text"
                  required={true}
                  onChange={this.props.handleChange()}
                  disabled={this.props.isLoading}
                  value={this.props.code || ''}
                />
                <span className="form-field__message">
                  Please make sure to check your spam folder if you don't see an
                  email from Trevelevel.
                </span>
              </div>
              <div className="form-field">
                <label htmlFor="password">Password</label>
                <input
                  id="password"
                  type="password"
                  required={true}
                  onChange={this.props.handleChange()}
                  disabled={this.props.isLoading}
                  value={this.props.password || ''}
                />
              </div>
              <div className="form-field">
                <label htmlFor="confirm-password">Confirm password</label>
                <input
                  id="password2"
                  type="password"
                  required={true}
                  onChange={this.props.handleChange()}
                  disabled={this.props.isLoading}
                  value={this.props.password2 || ''}
                />
              </div>
            </React.Fragment>
          )}
          {this.props.error && (
            <div className="message-box message-box--error">
              <h4>Oops...</h4>
              <p className="message-box__message">{this.props.error.message}</p>
            </div>
          )}
          <div className="button-container">
            <button
              type="submit"
              className="button"
              tabIndex={2}
              onClick={
                this.props.step === '1'
                  ? this.props.passwordReset
                  : this.props.passwordResetConfirm
              }
              disabled={!isValid || this.props.isLoading}
            >
              Continue
            </button>
            <a
              onClick={this.props.doLogin}
              disabled={this.props.isLoading}
              tabIndex={3}
            >
              Back
            </a>
          </div>
        </form>
      </React.Fragment>
    );
  }
}

PasswordReset.propTypes = {
  step: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  email: PropTypes.string,
  code: PropTypes.string,
  password: PropTypes.string,
  password2: PropTypes.string,

  handleChange: PropTypes.func.isRequired,

  doLogin: PropTypes.func.isRequired,
  passwordReset: PropTypes.func.isRequired,
  passwordResetConfirm: PropTypes.func.isRequired,

  error: PropTypes.shape({ message: PropTypes.string })
};

export default PasswordReset;
