export default {
  INITIATE_LOGIN: 'LOGIN_INITIATE',
  INITIATE_SIGNUP: 'SIGNUP_INITIATE',
  INITIATE_PASSWORD_RESET: 'INITIATE_PASSWORD_RESET',
  INITIATE_PASSWORD_RESET_CODE: 'INITIATE_PASSWORD_RESET_CODE',
  AWAIT_CONFIRMATION: 'AWAIT_CONFIRMATION',
  PROGRESS: 'LOGIN_PROGRESS',
  SUCCESS: 'LOGIN_SUCCESS',
  FAILURE: 'LOGIN_FAILURE',
  CANCEL: 'LOGIN_CANCEL',
  LOGOUT: 'LOGOUT'
};
