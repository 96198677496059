import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { userType } from '../../../types';
import { cleanPhoneNumber } from '../../../lib/utils';

class Account extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      email: props.user.email,
      name: props.user.name,
      phone: props.user.phone
    };

    this.handleChange = this.handleChange.bind(this);
    this.update = this.update.bind(this);
  }

  handleChange(processor = value => value) {
    return element => {
      this.setState({ [element.target.id]: processor(element.target.value) });
    };
  }

  update() {
    this.props.updateProfile(
      this.state.email,
      this.state.name,
      this.state.phone
    );
  }

  render() {
    const isValid = this.state.name && this.state.email;
    const isDeleting = Boolean(this.props.delete);

    const actions = isDeleting ? (
      <React.Fragment>
        <div className="message-box message-box--error">
          <h4>Warning</h4>
          <p className="message-box__message">
            Deleting your account will remove all the data about your travel as
            well as your login record. This operation cannot be undone. Please
            confirm that you want to delete your account by clicking the Delete
            button below
          </p>
        </div>
        <div className="button-container">
          <button
            type="submit"
            className="button danger"
            onClick={this.props.deleteProfile}
          >
            Delete
          </button>

          <a onClick={this.props.cancelProfileDelete}>Cancel</a>
        </div>
      </React.Fragment>
    ) : (
      <div className="button-container">
        <button
          type="submit"
          className="button"
          onClick={this.update}
          disabled={!isValid || this.props.progress}
        >
          Update
        </button>

        <a onClick={this.props.initiateProfileDelete} className="danger">
          Delete Account
        </a>
      </div>
    );

    return (
      this.props.user && (
        <div className="modal profile visible">
          <div
            className={classNames('progress', { visible: this.props.progress })}
          >
            <div className="inner"> </div>
          </div>

          <h1>Account</h1>
          <form className="form">
            <div className="form-field">
              <label htmlFor="email">Email</label>
              <input
                id="email"
                type="email"
                required={true}
                onChange={this.handleChange()}
                disabled={true}
                value={this.state.email}
              />
              {!isDeleting && (
                <span className="form-field__message">
                  Your email is your login and for right now we do not support
                  changing it.
                </span>
              )}
            </div>
            {!isDeleting && (
              <React.Fragment>
                <div className="form-field">
                  <label htmlFor="name">Name</label>
                  <input
                    id="name"
                    type="text"
                    required={true}
                    disabled={this.props.progress}
                    onChange={this.handleChange()}
                    value={this.state.name}
                  />
                </div>

                <div className="form-field">
                  <label htmlFor="phone">Phone</label>
                  <input
                    id="phone"
                    type="text"
                    disabled={this.props.progress}
                    onChange={this.handleChange(cleanPhoneNumber)}
                    value={this.state.phone}
                  />
                  <span className="form-field__message">
                    Only if you want to be able to text Trevelevel. A phone
                    number must start with a plus (+) sign, followed immediately
                    by the country code. We will strip out everything else as
                    you type.
                  </span>
                </div>
              </React.Fragment>
            )}

            {this.props.error && (
              <div className="message-box message-box--error">
                <h4>Oops...</h4>
                <p className="message-box__message">
                  {this.props.error.message}
                </p>
              </div>
            )}

            {actions}
          </form>
        </div>
      )
    );
  }
}

Account.propTypes = {
  user: userType.isRequired,
  progress: PropTypes.bool,
  error: PropTypes.shape({ message: PropTypes.string }),
  // state of the delete sub-journey
  delete: PropTypes.bool,
  // actions
  updateProfile: PropTypes.func.isRequired,
  initiateProfileDelete: PropTypes.func.isRequired,
  cancelProfileDelete: PropTypes.func.isRequired,
  deleteProfile: PropTypes.func.isRequired
};

export default Account;
