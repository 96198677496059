import Amplify, { Auth, API, PubSub } from 'aws-amplify';
import { AWSIoTProvider } from '@aws-amplify/pubsub/lib/Providers';

// ToDo: consider moving to https://github.com/kelektiv/node-uuid
// From: https://stackoverflow.com/questions/105034/create-guid-uuid-in-javascript#answer-2117523
const uuidv4 = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, match => {
    // eslint-disable-next-line no-bitwise
    const random = (Math.random() * 16) | 0;
    // eslint-disable-next-line no-bitwise
    const replace = match === 'x' ? random : (random & 0x3) | 0x8;

    return replace.toString(16);
  });
};

// window.LOG_LEVEL = 'VERBOSE';

Amplify.configure({
  Auth: {
    identityPoolId: 'us-east-1:6f4e357c-1529-4ef8-b36a-b7081c730019',
    region: 'us-east-1',
    userPoolId: 'us-east-1_ggbAruQkF',
    userPoolWebClientId: '4docodhbmr4sq5881h8sgg6pqd',
    mandatorySignIn: true
    // ToDo: do we need cookie storage ?
    /*
    cookieStorage: {
    }
  */
  },
  Analytics: {
    disabled: true
  },
  PubSub: {
    // ToDo: this was not in the examples and I wonder if I can omit it
    clientId: uuidv4()
  },
  API: {
    endpoints: [
      {
        name: 'trevelevel',
        endpoint: process.env.REACT_APP_OFFLINE
          ? 'http://localhost:3000'
          : 'https://api.trevelevel.com/v1'
      }
    ]
  }
});

PubSub.addPluggable(
  new AWSIoTProvider({
    aws_pubsub_region: 'us-east-1',
    aws_pubsub_endpoint:
      'wss://au32a8zj1e5nt-ats.iot.us-east-1.amazonaws.com/mqtt'
  })
);

export { Auth, API, PubSub };
