import { createAction } from 'redux-actions';
import ActionTypes from '../actionTypes';

import api from '../../../lib/api';
import { normalizeError } from '../../../lib/utils';

const startProfileShare = createAction(ActionTypes.PROFILE_SHARE_INITIATED);
const profileShareProcessing = createAction(
  ActionTypes.PROFILE_SHARE_PROCESSING
);
const profileShareUpdated = createAction(ActionTypes.PROFILE_SHARE_UPDATED);
const profileShareError = createAction(ActionTypes.PROFILE_SHARE_ERROR);

export default {
  initiateProfileSharing: () => dispatch => {
    dispatch(startProfileShare());
  },

  // shared indicate if the user decided to mark their profile as public or not
  // the api call is performed in context of the currently authenticated user
  shareProfile: (share = false) => dispatch => {
    dispatch(profileShareProcessing());

    api
      .shareProfile(share)
      .then(record => dispatch(profileShareUpdated(record)))
      .catch(error => dispatch(profileShareError(normalizeError(error))));
  }
};
