import ActionTypes from './actionTypes';

// ToDo: support password reset
// ToDo: support signup confirmation

export default (state, action) => {
  switch (action.type) {
    case ActionTypes.LOGOUT:
      return {
        user: undefined,
        logout: true
      };
    case ActionTypes.INITIATE_LOGIN:
      return {
        mode: 'signin',
        cancelled: false,
        logout: false
      };
    case ActionTypes.INITIATE_SIGNUP:
      return {
        mode: 'signup',
        cancelled: false,
        logout: false
      };
    case ActionTypes.INITIATE_PASSWORD_RESET:
      return {
        mode: 'password_reset_1',
        cancelled: false,
        logout: false
      };
    case ActionTypes.INITIATE_PASSWORD_RESET_CODE:
      return {
        mode: 'password_reset_2',
        cancelled: false,
        logout: false
      };
    case ActionTypes.PROGRESS:
      return {
        ...state,
        progress: true,
        error: undefined
      };
    case ActionTypes.FAILURE:
      return {
        ...state,
        progress: false,
        error: action.payload
      };
    case ActionTypes.SUCCESS:
      return {
        progress: false,
        error: undefined,
        user: action.payload
      };
    case ActionTypes.AWAIT_CONFIRMATION:
      return {
        mode: 'confirmation'
      };
    case ActionTypes.CANCEL:
      return {
        cancelled: true
      };
    default:
      return state || {};
  }
};
