import {
  startOfYear,
  differenceInCalendarDays,
  isBefore,
  addDays,
  getYear,
  isAfter,
  isWeekend,
  startOfDay,
  startOfMonth,
  setMonth,
  lastDayOfMonth,
  addYears,
  endOfYear,
  addMonths,
  getMonth
} from 'date-fns';

import { holidaysIn } from './holidays';
import {
  MODE_LAST_12_MONTH,
  MODE_THIS_YEAR,
  MODE_LAST_YEAR
} from './constants';

export const totalDays = (start, end) => {
  const holidaysAtStart = holidaysIn(getYear(start));
  const holidaysAtEnd = holidaysIn(getYear(end));

  const daysTotal = differenceInCalendarDays(end, start);

  let daysBusiness = daysTotal;
  for (let day = start; isBefore(day, end); day = addDays(day, 1)) {
    if (
      holidaysAtStart.isHoliday(day) ||
      holidaysAtEnd.isHoliday(day) ||
      isWeekend(day)
    ) {
      daysBusiness -= 1;
    }
  }

  return {
    daysTotal,
    daysBusiness
  };
};

export const traveledDays = (start, end, dates = []) => {
  const holidaysAtStart = holidaysIn(getYear(start));
  const holidaysAtEnd = holidaysIn(getYear(end));

  const traveledToDate = dates.filter(
    date =>
      isAfter(date, addDays(start, -1)) &&
      differenceInCalendarDays(end, date) > 0
  );

  const daysTraveledTotal = traveledToDate.length;
  const daysTraveledBusiness = traveledToDate.filter(
    date =>
      !holidaysAtStart.isHoliday(date) &&
      !holidaysAtEnd.isHoliday(date) &&
      !isWeekend(date)
  ).length;

  return {
    daysTraveledTotal,
    daysTraveledBusiness
  };
};

export const calculate = (
  dates = [],
  start = startOfYear(new Date()),
  end = addDays(startOfDay(new Date()), 1) // not inclusive that's why we set it to "tomorrow"
) => {
  const total = totalDays(start, end);
  const traveled = traveledDays(start, end, dates);

  // On Jan 1st, when looking at the stats for the current year or the month,
  // the dasyBusiness is 0 while daysTotal is 1

  return {
    pessimistic: Math.ceil(
      (100 * traveled.daysTraveledBusiness) / total.daysTotal
    ),
    normal: Math.ceil(
      (100 * traveled.daysTraveledBusiness) / (total.daysBusiness || 1)
    ),
    family: Math.ceil(
      (100 * traveled.daysTraveledTotal) / (total.daysBusiness || 1)
    )
  };
};

export const calculateMonthly = (dates = [], mode = MODE_THIS_YEAR) => {
  if (mode === MODE_THIS_YEAR) {
    const today = startOfMonth(new Date());

    return [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]
      .slice(0, getMonth(today) + 1)
      .reduce((result, month) => {
        const start = setMonth(today, month);
        const end = addDays(lastDayOfMonth(start), 1);

        result[`M-${month}`] = calculate(dates, start, end);

        return result;
      }, {});
  } else if (mode === MODE_LAST_YEAR) {
    const today = startOfMonth(addYears(new Date(), -1));

    return [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].reduce((result, month) => {
      const start = setMonth(today, month);
      const end = addDays(lastDayOfMonth(start), 1);

      result[`M-${month}`] = calculate(dates, start, end);

      return result;
    }, {});
  } else if (mode === MODE_LAST_12_MONTH) {
    const result = {};
    const today = startOfMonth(new Date());

    for (let delta = -11; delta <= 0; delta++) {
      const start = addMonths(today, 0 + delta);
      const end = addDays(lastDayOfMonth(start), 1);

      result[`M-${getMonth(start)}`] = calculate(dates, start, end);
    }

    return result;
  } else {
    throw new Error(`Unsupported mode ${mode}`);
  }
};

export const calculateForMode = (dates = [], mode = MODE_LAST_12_MONTH) => {
  switch (mode) {
    case MODE_THIS_YEAR:
      return calculate(dates);
    case MODE_LAST_YEAR: {
      const start = startOfYear(addYears(new Date(), -1));
      const end = endOfYear(addYears(new Date(), -1));

      return calculate(dates, start, end);
    }
    case MODE_LAST_12_MONTH: {
      const start = startOfMonth(addMonths(new Date(), -11));

      return calculate(dates, start);
    }
    default:
      throw new Error(`Unsupported mode ${mode}`);
  }
};
