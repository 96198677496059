import { createAction } from 'redux-actions';
import ActionTypes from '../actionTypes';
import { parse, isEqual } from 'date-fns';
import AWS from 'aws-sdk';

import { Auth, PubSub } from '../../../lib/amplify';
import api from '../../../lib/api';
import { normalizeError } from '../../../lib/utils';

const journalLoading = createAction(ActionTypes.JOURNAL_LOADING);
const journalLoaded = createAction(ActionTypes.JOURNAL_LOADED);
const journalError = createAction(ActionTypes.JOURNAL_ERROR);
const closePopups = createAction(ActionTypes.CLOSE_JOURNAL_POPUPS);

// ToDo: the data structure coming from the API will be more complex to accomodate half-day travel
const dispatchLoaded = (record, dispatch) => {
  dispatch(
    journalLoaded({
      calendar: (record.calendar || []).map(parse),
      shared: Boolean(record.shared)
    })
  );
};

// ToDo: migrate to async/await
const subscribeToIoT = (owner, credentials, user) => {
  if (owner.subscription) {
    console.log('Already subscribed to IoT');
    return;
  }

  console.log('Subscribing to IoT topic');

  const iot = new AWS.Iot({
    region: 'us-east-1',
    credentials: Auth.essentialCredentials(credentials)
  });

  const policyName = 'TravelwhelmIoTPolicy';
  const target = credentials._identityId;

  iot
    .listAttachedPolicies({ target })
    .promise()
    .then(({ policies }) => {
      return !policies.find(policy => policy.policyName === policyName);
    })
    .then(missing => {
      return missing
        ? iot.attachPolicy({ policyName, target }).promise()
        : Promise.resolve();
    })
    .then(() => {
      const { email } = user.signInUserSession.idToken.payload;

      owner.subscription = PubSub.subscribe(email).subscribe({
        next: ({ value }) => {
          console.log('Received IoT notification');
          owner.props.notify(value);
        },
        error: error => console.error(error),
        close: () => console.log('Topic closed')
      });
    })
    .catch(error => {
      console.error(error);
    });
};

export default {
  close: () => dispatch => {
    dispatch(closePopups());
  },

  // shared indicates if we're loading somebody else's public profile
  // we use "public" elsewhere but on its own it's a reserved keyword in JS
  load: (email, shared) => dispatch => {
    dispatch(journalLoading());

    api
      .load(email, shared)
      .then(record => dispatchLoaded(record, dispatch))
      .catch(error => dispatch(journalError(normalizeError(error))));
  },

  // ToDo: we need to migrate to RxJs. Sending these clicks needs to be delayed
  // the api call is performed in context of the currently authenticated user
  update: (traveled, notTraveled) => (dispatch, getState) => {
    dispatch(journalLoading());

    // ToDo: is this a good way to do latency compensation?
    const calendar = getState().journal.calendar.slice();

    notTraveled.forEach(date => {
      const index = calendar.findIndex(el => isEqual(el, date));
      if (index >= 0) {
        calendar.splice(index, 1);
      }
    });
    calendar.splice(0, 0, ...traveled);

    dispatchLoaded({ calendar }, dispatch);

    // ToDo: we don't dop anything about the errors thrown here
    api
      .updateCalendar(traveled, notTraveled)
      .then(record => dispatchLoaded(record, dispatch))
      .catch(error => dispatch(journalError(normalizeError(error))));
  },

  subscribe: owner => () => {
    Promise.all([Auth.currentCredentials(), Auth.currentAuthenticatedUser()])
      .then(([credentials, user]) => {
        subscribeToIoT(owner, credentials, user);
      })
      .catch(error => {
        console.error(error);
      });
  },

  notify: record => dispatch => {
    dispatchLoaded(record, dispatch);
  }
};
