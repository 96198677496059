import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class Signin extends PureComponent {
  render() {
    const isValid = this.props.email && this.props.password;

    // ToDo: how to ensure that tabIndex works as expected within this form popup?

    return (
      <React.Fragment>
        <h1>Sign in</h1>
        <form className="form">
          <div className="form-field">
            <label htmlFor="email">Email</label>
            <input
              id="email"
              type="email"
              required={true}
              autoFocus={true}
              tabIndex={0}
              onChange={this.props.handleChange()}
              disabled={this.props.isLoading}
              value={this.props.email || ''}
            />
          </div>
          <div className="form-field">
            <label htmlFor="password">
              Password{' '}
              <span className="forgot-password">
                (
                <a onClick={this.props.doPasswordReset} tabIndex={4}>
                  help me remember
                </a>
                )
              </span>
            </label>
            <input
              id="password"
              type="password"
              required={true}
              tabIndex={1}
              onChange={this.props.handleChange()}
              disabled={this.props.isLoading}
              value={this.props.password || ''}
            />
          </div>
          {this.props.error && (
            <div className="message-box message-box--error">
              <h4>Oops...</h4>
              <p className="message-box__message">{this.props.error.message}</p>
            </div>
          )}
          <div className="button-container">
            <button
              type="submit"
              className="button"
              tabIndex={2}
              onClick={this.props.login}
              disabled={!isValid || this.props.isLoading}
            >
              Sign in
            </button>
            <a
              onClick={this.props.doSignUp}
              disabled={this.props.isLoading}
              tabIndex={3}
            >
              Sign Up
            </a>
          </div>
        </form>
      </React.Fragment>
    );
  }
}

Signin.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  email: PropTypes.string,
  password: PropTypes.string,

  handleChange: PropTypes.func.isRequired,
  login: PropTypes.func.isRequired,

  doSignUp: PropTypes.func.isRequired,
  doPasswordReset: PropTypes.func.isRequired,

  error: PropTypes.shape({ message: PropTypes.string })
};

export default Signin;
