import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

import journalActions from './actions/journal';
import profileActions from './actions/profile';
import shareActions from './actions/share';
import loginActions from '../../components/Login/actions';

import { userType } from '../../types';
import JournalHeader from '../../components/JournalHeader';
import TravelCalendar from '../../components/TravelCalendar';
import TravelMeter from '../../components/TravelMeter';
import TravelChart from '../../components/TravelChart';
import { MODE_LAST_12_MONTH } from '../../lib/constants';

class Journal extends Component {
  constructor(props) {
    super(props);

    this.state = { mode: MODE_LAST_12_MONTH };

    this.changeMode = this.changeMode.bind(this);
  }

  componentDidMount() {
    this.ready();
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.user && this.props.user) {
      // successfull sign in
      this.ready();
    }
  }

  // ToDo: what is the best way to refactor this one?
  ready() {
    // the render() method will redirect back to home page
    if (this.props.loginActionRequiresRedirect) {
      return;
    }

    // require login for private profiles views
    if (!this.props.public && !this.props.user) {
      this.props.login();
      return;
    }

    // the render() method will redirect to the right personal profile
    if (!this.props.public && this.props.email !== this.props.user.email) {
      return;
    }

    // ToDo: process the "not found" error when the requested profile is not public
    this.props.load(this.props.email, this.props.public);

    // ToDo: the message on the screen should probably say that public pages don't have a live feed
    if (!this.props.public && !this.subscription) {
      // ToDo: let's see how/if we can subscribe an anonymous user viewing a shared profile
      this.props.subscribe(this);
    }
  }

  componentWillUnmount() {
    // ToDo: I never see the unmount event
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  changeMode(selected) {
    this.setState({ mode: selected });
  }

  render() {
    // ToDo: process not found event and make sure we properly handle public vs. not-public access denied errors

    document.body.classList.remove('with-stroke');

    if (this.props.loginActionRequiresRedirect) {
      return <Redirect to="/" />;
    }

    if (
      !this.props.public &&
      this.props.user &&
      this.props.email !== this.props.user.email
    ) {
      return <Redirect to={`/${this.props.user.email}`} />;
    }

    const interactive = !this.props.public && this.props.user;

    return (
      <React.Fragment>
        <header className="simple-header">
          <JournalHeader
            public={this.props.public}
            shared={this.props.shared}
            email={this.props.email}
            user={this.props.user}
            // state of popups
            profile={this.props.profile}
            share={this.props.share}
            // actions available to/from popups
            logout={this.props.logout}
            close={this.props.close}
            initiateProfileUpdate={this.props.initiateProfileUpdate}
            initiateProfileDelete={this.props.initiateProfileDelete}
            cancelProfileDelete={this.props.cancelProfileDelete}
            deleteProfile={this.props.deleteProfile}
            updateProfile={this.props.updateProfile}
            initiateProfileSharing={this.props.initiateProfileSharing}
            shareProfile={this.props.shareProfile}
          />
        </header>
        <main className="content">
          <section className="calendar">
            <TravelCalendar
              calendar={this.props.calendar}
              update={interactive && this.props.update}
            />
          </section>
          <section className="tabs">
            <Tabs
              className="container"
              selectedIndex={this.state.mode}
              onSelect={this.changeMode}
            >
              <TabList className="tab-list">
                <Tab className="tab-header">This Year</Tab>
                <Tab className="tab-header">Last Year</Tab>
                <Tab className="tab-header">Last 12 Months</Tab>
              </TabList>
              <TabPanel />
              <TabPanel />
              <TabPanel />
            </Tabs>
          </section>
          <section className="pie-charts">
            <TravelMeter
              calendar={this.props.calendar}
              progress={this.props.progress || this.props.login_progress}
              mode={this.state.mode}
            />
          </section>
          <section className="line-charts">
            <TravelChart
              calendar={this.props.calendar}
              mode={this.state.mode}
            />
          </section>
        </main>
      </React.Fragment>
    );
  }
}

// public indicates if I am looking at someone else's page through their /public URL
// shared indicates if my record is public or not.

Journal.propTypes = {
  // passed on from the route (App)
  public: PropTypes.bool.isRequired,
  email: PropTypes.string.isRequired,

  // state.auth
  user: userType,
  loginActionRequiresRedirect: PropTypes.bool,

  // state.journal
  calendar: PropTypes.array,
  shared: PropTypes.bool,
  progress: PropTypes.bool,
  error: PropTypes.shape({ message: PropTypes.string }),

  // state of the two popups
  profile: PropTypes.object,
  share: PropTypes.object,

  // actions (journal)
  close: PropTypes.func.isRequired,
  update: PropTypes.func.isRequired,
  load: PropTypes.func.isRequired,
  notify: PropTypes.func.isRequired,
  subscribe: PropTypes.func.isRequired,

  // actions (profile)
  initiateProfileUpdate: PropTypes.func.isRequired,
  initiateProfileDelete: PropTypes.func.isRequired,
  cancelProfileDelete: PropTypes.func.isRequired,
  deleteProfile: PropTypes.func.isRequired,
  updateProfile: PropTypes.func.isRequired,

  // actions (share)
  initiateProfileSharing: PropTypes.func.isRequired,
  shareProfile: PropTypes.func.isRequired,

  // login progress to show when loading
  login_progress: PropTypes.bool,

  // action (login)
  login: PropTypes.func,
  logout: PropTypes.func
};

export default connect(
  state => ({
    ...state.journal,
    user: state.auth.user,
    login_progress: state.auth.progress,
    loginActionRequiresRedirect: state.auth.cancelled || state.auth.logout
  }),
  {
    ...journalActions,
    ...profileActions,
    ...shareActions,
    login: loginActions.login,
    logout: loginActions.logout
  }
)(Journal);
