import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { getYear } from 'date-fns';

import { userType } from '../../types';
import loginActions from '../../components/Login/actions';

import webIcon from '../../img/www-img.png';
import alexaIcon from '../../img/alexa-img.png';
import chatIcon from '../../img/chat-img.png';

class Welcome extends Component {
  constructor(props) {
    super(props);

    this.state = { popup: undefined };

    this.howItWorks = React.createRef();
    this.aboutUs = React.createRef();

    this.openPopup = this.openPopup.bind(this);
    this.closePopup = this.closePopup.bind(this);
    this.scrollTo = this.scrollTo.bind(this);
  }

  scrollTo(ref) {
    return () =>
      this[ref].current.scrollIntoView({
        behavior: 'smooth',
        block: 'start'
      });
  }

  openPopup(name) {
    return () => this.setState({ popup: name });
  }

  closePopup() {
    this.setState({ popup: undefined });
  }

  render() {
    if (this.props.user && this.props.user.email) {
      return <Redirect to={`/${this.props.user.email}`} />;
    }

    document.body.classList.add('with-stroke');

    return (
      <div>
        <header className="header">
          <div className="container">
            <div className="header-container">
              <h1>What is Trevelevel?</h1>
              <button
                type="button"
                id="proceed-button"
                className="proceed-button"
                onClick={this.scrollTo('howItWorks')}
              />
            </div>
          </div>
        </header>
        <main className="content">
          <section className="about">
            <div className="container">
              <p className="description">
                Trevelevel helps you track how much you travel and see how much
                people around you think you travel.
              </p>
              <div className="block">
                <div className="block-item my-boss">
                  <span className="title">My Boss</span>
                  <span className="digit">25</span>
                </div>
                <div className="block-item myself">
                  <span className="title">Myself</span>
                  <span className="digit">40</span>
                </div>
                <div className="block-item other">
                  <span className="title">My significant other</span>
                  <span className="digit">65</span>
                </div>
              </div>
              <div className="button-container">
                <button
                  type="button"
                  className="button"
                  onClick={this.props.login}
                >
                  Sign Up or Sign In
                </button>
              </div>
            </div>
          </section>
          <section
            className="how-it-works"
            id="how-it-works"
            ref={this.howItWorks}
          >
            <div className="container">
              <h1>How it works?</h1>
              <p className="description">
                You mark your travel days on the calendar and Trevelevel will
                calculate how much you travel. Trevelevel uses three simple
                formulas – one that reflects a typical opinion of an employer,
                one that is common for a self-reflecting professional, and one
                that is likely to be used by people who like spending their time
                with you.
              </p>
              <p className="description">
                Right now Trevelevel works with a standard US holiday schedule.
                If you come from a different part of the world or would just
                like the ability to configure your own holiday schedule to get a
                more accurate measurement of how much you travel, please{' '}
                <a onClick={this.scrollTo('aboutUs')}>reach out</a>!
              </p>
            </div>
          </section>
          <section className="details">
            <div className="container">
              <h1>Where can I find it?</h1>
              <div className="block">
                <div className="block-item" onClick={this.openPopup('web')}>
                  <img src={webIcon} alt="" />
                  <span className="name">Trevelevel.com</span>
                  <a>More details</a>
                </div>
                <div className="block-item" onClick={this.openPopup('alexa')}>
                  <img src={alexaIcon} alt="" />
                  <span className="name">Alexa Skill</span>
                  <a>More details</a>
                </div>
                <div className="block-item" onClick={this.openPopup('chat')}>
                  <img src={chatIcon} alt="" />
                  <span className="name">Chatbot</span>
                  <a>More details</a>
                </div>
              </div>
            </div>
          </section>
        </main>
        <footer className="footer">
          <div className="container">
            <h1 ref={this.aboutUs}>About Us</h1>
            <table className="build-by">
              <tbody>
                <tr>
                  <td>Designed by:</td>
                  <td>@flick_flick</td>
                </tr>
                <tr>
                  <td>Built by:</td>
                  <td>@pveller</td>
                </tr>
                <tr>
                  <td />
                  <td>@kirillmalets</td>
                </tr>
              </tbody>
            </table>
            <p className="copyright">
              &copy; {getYear(new Date())} EPAM Systems, Inc.{' '}
            </p>
          </div>
        </footer>
        <div
          className={classNames(['modal', 'modal-web'], {
            visible: this.state.popup === 'web'
          })}
        >
          <img src={webIcon} alt="" />
          <p>You are right here! Just sign up and then sign in.</p>
          <p>Enjoy!</p>
        </div>
        <div
          className={classNames(['modal', 'modal-alexa'], {
            visible: this.state.popup === 'alexa'
          })}
        >
          <img src={alexaIcon} alt="" />
          <p className="smallprint">[coming soon]</p>
          <p>Sign up and install the Trevelevel Alexa skill.</p>
          <p>Once you link your account you will be able to say things like:</p>
          <ul>
            <li>
              Alexa, tell Travel Level that I was in New York last week
              Wednesday
            </li>
            <li>
              Alexa, tell Travel Level that I will be in Dubai on October 5th
              and 6th
            </li>
          </ul>
        </div>
        <div
          className={classNames(['modal', 'modal-chat'], {
            visible: this.state.popup === 'chat'
          })}
        >
          <img src={chatIcon} alt="" />
          <p>
            Sign up with your phone number and text Trevelevel at
            <a href="tel:+1-844-556-0515"> +1-844-556-0515</a>
          </p>
          <p>You can say things like:</p>
          <ul>
            <li>Please mark last week Monday and Tuesday as travel days</li>
            <li>I will be out of town next week Tuesday through Friday</li>
          </ul>
        </div>
        <div
          className={classNames('overlay', {
            visible: Boolean(this.state.popup)
          })}
          onClick={this.closePopup}
        >
          <button
            onClick={this.closePopup}
            type="button"
            className="button-close"
          />
        </div>
      </div>
    );
  }
}

Welcome.propTypes = {
  login: PropTypes.func.isRequired,
  user: userType
};

export default connect(
  state => ({ user: state.auth.user }),
  {
    login: loginActions.login
  }
)(Welcome);
