import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { parse } from 'date-fns';
import classNames from 'classnames';

import { calculateForMode } from '../../lib/indicator';
import { isCalendarEqual } from '../../lib/calendar';

// ToDo: maybe move this CSS to the component level?
import '../../css/circle.css';

class TravelMeter extends Component {
  shouldComponentUpdate(nextProps) {
    return (
      nextProps.progress !== this.props.progress ||
      nextProps.mode !== this.props.mode ||
      !isCalendarEqual(nextProps.calendar, this.props.calendar)
    );
  }

  render() {
    const meter = calculateForMode(
      this.props.calendar.map(parse),
      this.props.mode
    );

    return (
      <div className="container">
        <div className={classNames('block', { loading: this.props.progress })}>
          <div className="block-item">
            <div className={`c100 p${meter.pessimistic} big center yellow`}>
              <span className="donut-inner">{meter.pessimistic}</span>
              <div className="slice">
                <div className="bar" />
                <div className="fill" />
              </div>
            </div>
            <span className="name">My boss</span>
            <span className="caption">
              How your boss likely thinks about your travel
            </span>
          </div>
          <div className="block-item">
            <div className={`c100 p${meter.normal} big center blue`}>
              <span className="donut-inner">{meter.normal}</span>
              <div className="slice">
                <div className="bar" />
                <div className="fill" />
              </div>
            </div>
            <span className="name">Myself</span>
            <span className="caption">
              This is how you probably think about your travel
            </span>
          </div>
          <div className="block-item">
            <div className={`c100 p${meter.family} big center light-green`}>
              <span className="donut-inner">{meter.family}</span>
              <div className="slice">
                <div className="bar" />
                <div className="fill" />
              </div>
            </div>
            <span className="name">My significant other</span>
            <span className="caption">
              This is how a person who likes spending time with you thinks about
              your travel
            </span>
          </div>
        </div>
      </div>
    );
  }
}

TravelMeter.propTypes = {
  calendar: PropTypes.arrayOf(PropTypes.instanceOf(Date)).isRequired,
  progress: PropTypes.bool,
  mode: PropTypes.number.isRequired
};

export default TravelMeter;
