import { Auth, API } from './amplify';
import jwt from 'jsonwebtoken';
import format from 'date-fns/format';

// ToDo: it would be nice to migrate over to AWS_IAM but it doesn't work for some reason

const traveledTemplate = 'Please mark these dates as traveled: $1';
const notTraveledTemplate = 'Please mark these dates as not traveled: $1';

const toText = dates =>
  dates.map(date => format(date, 'MM/DD/YYYY')).join(', ');

const headers = (idToken, accessToken) => ({
  Authorization: idToken,
  'X-Trevelevel-Access': accessToken
});

const getUser = async () => {
  const user = await Auth.currentAuthenticatedUser();

  const { idToken, accessToken } = user.signInUserSession;
  const { email } = jwt.decode(idToken.jwtToken);

  return {
    idToken: idToken.jwtToken,
    accessToken: accessToken.jwtToken,
    email
  };
};

export const load = async (emailOf, shared = false) => {
  if (shared) {
    return API.get('trevelevel', `/public/${emailOf}`);
  }

  const { idToken, accessToken, email } = await getUser();

  if (email !== emailOf) {
    throw new Error(`Access denied to ${emailOf} for ${email}`);
  }

  return API.get('trevelevel', `/${email}`, {
    headers: headers(idToken, accessToken)
  });
};

export const shareProfile = async (shared = false) => {
  const { idToken, accessToken, email } = await getUser();

  const action = shared ? 'share' : 'unshare';

  return API.post('trevelevel', `/${email}/${action}`, {
    headers: headers(idToken, accessToken)
  });
};

export const deleteProfile = async () => {
  const { idToken, accessToken, email } = await getUser();

  return API.del('trevelevel', `/${email}`, {
    headers: headers(idToken, accessToken)
  });
};

export const updateProfile = async (email, name, phone) => {
  const user = await Auth.currentAuthenticatedUser();

  // ToDo: we do not support changing an email so we don't have to worry about logging the user out
  // to change someone's email we would need to re-confirm their login (email) but more importantly
  // we would need to change the email on the DynamoDB record

  await Auth.updateUserAttributes(user, {
    name,
    phone_number: phone
  });

  return { logout: false };
};

export const updateCalendar = async (markAsTraveled, markAsNotTraveled) => {
  const traveled = toText(markAsTraveled);
  const notTraveled = toText(markAsNotTraveled);

  const request = [];
  request.push(traveled && traveledTemplate.replace('$1', traveled));
  request.push(notTraveled && notTraveledTemplate.replace('$1', notTraveled));

  const { idToken, accessToken, email } = await getUser();

  return API.post('trevelevel', `/${email}`, {
    headers: headers(idToken, accessToken),
    body: request.filter(Boolean).join('. ')
  });
};

export default {
  load,
  updateCalendar,
  shareProfile,
  updateProfile,
  deleteProfile
};
