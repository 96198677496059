import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { userType } from '../../../types';

class Share extends PureComponent {
  constructor(props) {
    super(props);

    this.share = this.share.bind(this);
  }

  share(element) {
    this.props.shareProfile(element.target.checked);
  }

  render() {
    return (
      <div className="modal share visible">
        <div
          className={classNames('progress', { visible: this.props.progress })}
        >
          <div className="inner"> </div>
        </div>

        <h1>Share</h1>
        <div className="switch-container">
          <span className="label">Public</span>
          <label className="switch">
            <input
              type="checkbox"
              defaultChecked={this.props.shared}
              onChange={this.share}
            />
            <span className="slider round" />
          </label>
        </div>
        {this.props.error && (
          <div className="message-box message-box--error">
            <h4>Oops...</h4>
            <p className="message-box__message">{this.props.error.message}</p>
          </div>
        )}
        <hr />
        {this.props.shared && (
          <React.Fragment>
            <label>Your public URL:</label>
            <div className="url">
              <a
                href={`https://www.trevelevel.com/#/public/${
                  this.props.user.email
                }`}
                target="_blank"
                rel="noopener noreferrer"
              >
                www.trevelevel.com/#/public/
                {this.props.user.email}
              </a>
            </div>
          </React.Fragment>
        )}
      </div>
    );
  }
}

Share.propTypes = {
  user: userType.isRequired,
  shared: PropTypes.bool.isRequired,
  progress: PropTypes.bool,
  error: PropTypes.shape({ message: PropTypes.string }),
  // actions
  shareProfile: PropTypes.func.isRequired
};

export default Share;
